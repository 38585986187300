import * as styles from './index.module.scss';

import { useState, useEffect } from 'react';

import { useTrackedPhoneNumber } from '../TrackedPhoneNumber';
import PhoneIcon from 'svgs/phone.svg';

const StickyPhoneBubble = () => {
  const [state, setState] = useState({
    show: false
  });

  const { tryTrackEvent, phoneNumber } = useTrackedPhoneNumber();

  useEffect(() => {
    const updateMobileCTAState = () => {
      setState({
        ...state,
        show: window.scrollY > window.innerHeight / 2
      });
    };
    window.addEventListener('scroll', updateMobileCTAState);
    return () => {
      window.removeEventListener('scroll', updateMobileCTAState);
    };
  }, [state]);

  return (
    <div className={`${styles['sticky-phone-bubble-container']} ${state.show ? styles['show'] : ''}`}>
      <a href={`tel:${phoneNumber}`} aria-label="phone number" tabIndex={0} onClick={tryTrackEvent}>
        <PhoneIcon />
      </a>
    </div>
  );
};

export default StickyPhoneBubble;
