import React from 'react';
import Head from 'next/head';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { getArticlesByPage } from '@/helpers/helpers_data';

import ArticleCardsWithImage from '@/components/ArticleCardsWithImage';
import Footer from '@/components/Footer';
import Header from '@/components/Header';
import Hero from '@/components/Hero';
import HowItWorks from '@/components/HowItWorks';
// import PriceGuideLinks from '@/components/PriceGuideLinks';
import WhoWeAre from '@/components/WhoWeAre';
import StickyPhoneBubble from '@/components/StickyPhoneBubble';

export async function getStaticProps({ locale }) {
  const data = await getArticlesByPage({ limit: 6 });

  return {
    props: {
      articles: data.articles,
      ...(await serverSideTranslations(locale, ['common']))
    }
  };
}

const Home = ({ articles = [] }) => {
  const { t } = useTranslation();

  return (
    <div className="page-wrapper">
      <Head>
        <meta name="description" content="CallAPro - Find a Home Service Professional Near You" />
        <title>{t('find_home_service_pros_near_you')}</title>

        <link rel="canonical" href="https://callapro.com" />
      </Head>
      <Header />
      <Hero />
      <WhoWeAre />
      <HowItWorks />
      {/* <PriceGuideLinks /> */}
      <ArticleCardsWithImage articles={articles.slice(0, 6)} showCta={true} />
      <Footer />
      <StickyPhoneBubble />
    </div>
  );
};

export default Home;
